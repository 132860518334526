@import '~@/styles/abstracts.scss';

.wrapper {
  background: var(--color-background-main);
}

.firstBlockFixed {
  position: sticky;
  position: -webkit-sticky;
  width: 100%;
  left: 0;
  transform: translateZ(0);
  transform-style: preserve-3d;
  backface-visibility: hidden;
  perspective: 1000;

  z-index: 0;
}
.commentsBlockWrapper {
  position: relative;
  z-index: 2;
  transform: translateZ(0);
  transform-style: preserve-3d;
  backface-visibility: hidden;
}
.thirdBlock {
  position: relative;
  z-index: 2;
  transform: translateZ(0);
  transition: filter 0.3s;
}
.basementBlockWrapper {
  position: relative;
  z-index: 7;
  transform: translateZ(0);
  transform-style: preserve-3d;
  backface-visibility: hidden;
}
