@import '~@/styles/abstracts.scss';

.heading {
  color: var(--color-primary);
  font-weight: 900;
}

.main {
  font-size: 40px;
  line-height: 0.95em;
  letter-spacing: -0.048em;
  color: white;

  @include respond-above('bp-455') {
    font-size: 50px;
  }
  @include respond-above('bp-525') {
    font-size: 58px;
  }
  @include respond-above('bp-625') {
    font-size: 70px;
  }
  @include respond-above('bp-768') {
    font-size: 80px;
    line-height: 0.9em;
  }
  @include respond-above('bp-1010') {
    font-size: 95px;
    line-height: 0.86em;
  }
  @include respond-above('bp-1142') {
    font-size: 110px;
    line-height: 0.86em;
  }
}

.explanationSection {
  font-size: 34px;
  line-height: 0.98em;
  letter-spacing: -0.048em;

  @include respond-above('bp-455') {
    font-size: 36px;
  }
  @include respond-above('bp-525') {
    font-size: 40px;
  }
  @include respond-above('bp-625') {
    font-size: 45px;
  }
  @include respond-above('bp-768') {
    font-size: 56px;
  }
  @include respond-above('bp-900') {
    font-size: 70px;
    line-height: 1em;
  }
  @include respond-above('bp-1142') {
    font-size: 85px;
    line-height: 1.18em;
  }
}

.commentsSection {
  color: var(--color-background-main);
  font-size: 32px;
  line-height: 1em;
  letter-spacing: -0.048em;

  @include respond-above('bp-455') {
    font-size: 36px;
  }
  @include respond-above('bp-525') {
    font-size: 40px;
  }
  @include respond-above('bp-625') {
    font-size: 45px;
  }
  @include respond-above('bp-768') {
    font-size: 50px;
  }
  @include respond-above('bp-900') {
    font-size: 60px;
  }
  @include respond-above('bp-1010') {
    font-size: 70px;
    letter-spacing: -0.0336em;
  }
}
.opportunities {
  font-size: 26px;
  line-height: 1em;
  letter-spacing: -0.01em;

  @include respond-above('bp-455') {
    font-size: 28px;
  }
  @include respond-above('bp-525') {
    font-size: 30px;
  }
  @include respond-above('bp-625') {
    font-size: 32px;
  }
  // @include respond-above('bp-768') {
  //   font-size: 36px;
  //   line-height: 1.05em;
  // }
  @include respond-above('bp-900') {
    font-size: 40px;
    line-height: 1.1em;
  }
  // @include respond-above('bp-1010') {
  //   color: var(--color-index-page-invert-font);
  // }
}
.download,
.open,
.advantages {
  font-size: 22px;
  line-height: 1em;
  letter-spacing: -0.01em;

  @include respond-above('bp-525') {
    font-size: 24px;
  }
  @include respond-above('bp-768') {
    font-size: 26px;
  }
  @include respond-above('bp-900') {
    font-size: 30px;
    line-height: 1.17em;
  }
}
.basement {
  font-size: 35px;
  line-height: 1em;
  letter-spacing: -0.048em;

  @include respond-above('bp-455') {
    font-size: 40px;
  }
  @include respond-above('bp-525') {
    font-size: 50px;
  }
  @include respond-above('bp-625') {
    font-size: 60px;
  }
  @include respond-above('bp-768') {
    font-size: 60px;
    line-height: 0.9em;
  }
  @include respond-above('bp-900') {
    font-size: 60px;
  }
}

.secondary {
  font-weight: normal;
  font-size: 22px;
  line-height: 1em;
  letter-spacing: -0.01em;
}
