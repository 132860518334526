@import "~@/styles/abstracts.scss";

.section {
  color: var(--color-background-main);
  background: var(--color-primary);
  height: 100%;
  margin-top: 0px;
}

.commentsHeading {
  padding-top: 45px;

  @include respond-above("bp-768") {
    padding-top: 60px;
  }
  @include respond-above("bp-1010") {
    padding-top: 130px;
    margin: 0px -3px;
  }
}
.commentsBlock {
  padding-bottom: 73px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @include respond-above("bp-768") {
    padding-bottom: 70px;
  }
  @include respond-above("bp-1010") {
    justify-content: start;
    padding-bottom: 150px;
  }
}
.comment {
  padding-top: 17px;
  // max-width: 325px;
  max-width: 100%;
  @include respond-above("bp-625") {
    // max-width: 270px;
  }
  @include respond-above("bp-768") {
    // max-width: 320px;
    max-width: 100%;
  }
  @include respond-above("bp-1010") {
    // max-width: 380px;
    padding-top: 45px;
  }
}
// .comment:nth-child(1) {
//   @include respond-above('bp-1010') {
//     margin-right: 101px;
//   }
// }
.author {
  padding-top: 20px;
  display: flex;
  @include respond-above("bp-768") {
    padding-top: 24px;
  }
  @include respond-above("bp-1010") {
    font-size: 16px;
  }
}
.authorImg {
  --author-img-size: 38px;

  width: var(--author-img-size);
  height: var(--author-img-size);
  border-radius: 50%;
  overflow: hidden;
}
.authorImg img {
  display: block;
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.authorInfo {
  padding: 3px 0 0 10px;
}
