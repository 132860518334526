:root,
[data-theme="default"] {
  --color-primary: #000000;
  --color-index-page-invert-font: #000000;

  --color-contrast-alert: #fd535c;

  --color-background-main: #ffffff;
  --color-background-soft: #508889;
  --color-background-auth: #508889;
  --color-index-page: #ffffff;

  --color-placeholder: #8d8d8d;
}
