@import "~@/styles/abstracts.scss";

.header {
  display: flex;
  align-items: center;

  height: var(--header-height);
  overflow: hidden;
}

.page_title {
  width: 138px;
  display: flex;
  align-items: center;
  flex-shrink: 0;

  @include respond-above("bp-768") {
    width: 168px;
    height: 29px;
  }
}

.page_title_link {
  width: 100%;
  height: 100%;
}

.right_slot {
  min-width: 0;
  flex-grow: 0;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  align-items: stretch;
}
