@import '~@/styles/abstracts.scss';

.heading {
  p {
    margin: 0;
  }
}
.text {
  font-size: 14px;
  line-height: 1.21em;

  @include respond-above('bp-625') {
    font-size: 16px;
  }
  @include respond-above('bp-768') {
    font-size: 18px;
  }
  @include respond-above('bp-900') {
    font-size: 20px;
    line-height: 1.25em;
  }

  p {
    margin: 10px 0;
  }
  & :first-child {
    margin-top: 0;
  }

  & :last-child {
    margin-bottom: 0;
  }

  ul,
  ol {
    padding-left: 20px;
  }

  li:not(:last-of-type) {
    margin-bottom: 0.5em;
  }
}
