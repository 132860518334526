@import '~@/styles/abstracts.scss';

.container {
  --width-container: 679px;
  position: relative;
  margin-left: auto;
  margin-right: auto;

  height: 100%;
  width: calc(100% - 2 * var(--side-size));
  @include respond-above('bp-768') {
    width: var(--width-container);
  }
  @include respond-above('bp-900') {
    --width-container: 815px;
  }
  @include respond-above('bp-1142') {
    --width-container: 950px;
  }
}
