@import '~@/styles/abstracts.scss';

.section {
  background: var(--color-background-soft);
  height: 455px;

  @include respond-above('bp-455') {
    height: 500px;
  }
  @include respond-above('bp-525') {
    height: 510px;
  }
  @include respond-above('bp-625') {
    height: 550px;
  }
  @include respond-above('bp-768') {
    height: 700px;
  }
  @include respond-above('bp-1010') {
    height: 930px;
  }
}
.firstSectionMotionDiv {
  transform: translateZ(0);
  transform-style: preserve-3d;
  backface-visibility: hidden;
  perspective: 1000;
}

.sectionContainer {
  height: 100%;
  padding-bottom: 40px;
}

.mainHeading {
  margin-top: 50px;
  width: 290px;

  @include respond-above('bp-455') {
    margin-top: 50px;
    width: 405px;
  }
  @include respond-above('bp-525') {
    margin-top: 50px;
    width: 485px;
  }
  @include respond-above('bp-625') {
    margin-top: 40px;
    width: 585px;
  }
  @include respond-above('bp-768') {
    margin-top: 45px;
    width: 685px;
  }
  @include respond-above('bp-900') {
    margin-top: 21px;
    margin-left: -1px;
  }
  @include respond-above('bp-1010') {
    width: 100%;
  }
}

.main_controls {
  display: flex;

  margin-top: 22px;

  @include respond-above('bp-625') {
    margin-top: 36px;
  }
}

.start_button {
  margin-right: 15.5px;
}
