@import '~@/styles/abstracts.scss';

.buttons_row {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.button_link {
  flex-shrink: 0;
  display: flex;
  background: #000000;
  border-radius: 5px;

  &:hover {
    background: rgba($color: #000000, $alpha: 0.8);
  }
}
