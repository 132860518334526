@import "~@/styles/abstracts.scss";

:root {
  // Defaults
  --js-real-height: 100vh;
  --js-width-auth-modal: 681px;

  /// Regular font family
  --text-font-stack: "Montserrat", sans-serif;
  // --logo-text-font-stack: 'MADEEvolveSans', sans-serif;
  --doc-title-text-font-stack: "MADEEvolveSans", sans-serif;

  /// Code (monospace) font family
  --code-font-stack: "Courier New", "DejaVu Sans Mono", "Bitstream Vera Sans Mono", "Monaco", monospace;

  /// Base  fontsize
  --base-font-size: 14px;

  --min-page-width: 320px;

  /// Sizes
  --side-size: 15px;
  @include respond-above("bp-350") {
    --side-size: 20px;
  }
  @include respond-above("bp-455") {
    --side-size: 25px;
  }
  @include respond-above("bp-525") {
    --side-size: 30px;
  }
  @include respond-above("bp-625") {
    --side-size: 35px;
  }
  @include respond-above("bp-768") {
    --side-size: 45px;
  }

  --side-padding: 29px;
  --small-padding: 10px;
  --header-height: 72px;
  --navigation-width: 313px;
  --right-offset: 400px;
  --input-height: 50px;
  --input-width: 430px;

  @include respond-above("bp-768") {
    --header-height: 100px;
  }
  @include respond-above("bp-900") {
    --header-height: 160px;
  }
  @include respond-above("bp-1010") {
    --js-width-auth-modal: 800px;
  }

  /// Modal
  --side-padding-modal: 80px;
  --li-small-margin-modal: 1em;

  /// Values
  --box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.25);
}
