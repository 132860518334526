@import "~@/styles/abstracts.scss";

.section {
  height: 100%;
  overflow: hidden;
  background-color: var(--color-background-main);
}

.phoneImg {
  position: absolute;
  top: 780px;
  right: -23px;
  height: 510px;
}
.phoneImg img {
  height: 100%;
}
.opportunityBlock {
  padding-top: 50px;

  @include respond-above("bp-768") {
    display: flex;
    padding-top: 62px;
  }
  @include respond-above("bp-1010") {
    padding-top: 130px;
  }
}
.opportunityTextPart {
  max-width: 325px;

  @include respond-above("bp-455") {
    max-width: 400px;
  }
  @include respond-above("bp-525") {
    max-width: 435px;
  }
  @include respond-above("bp-625") {
    max-width: 505px;
  }
  @include respond-above("bp-768") {
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: 50%;
    padding-right: 30px;
    padding-top: 10px;
  }
  @include respond-above("bp-1010") {
    flex-basis: 53.4%;
    padding-right: 50px;
  }
  @include respond-above("bp-1142") {
    flex-basis: 48.4%;
    padding-right: 60px;
    padding-top: 40px;
  }
}
.opportunityText {
  padding-top: 15px;
  @include respond-above("bp-1010") {
    padding-top: 21px;
  }
}

.opportunityImgBlock {
  padding-top: 19px;
  width: 100%;

  @include respond-above("bp-768") {
    flex-basis: 50%;
    padding-top: 0px;
  }

  & > img {
    border-radius: 5px;
    overflow: hidden;
  }
}
.opportunityImgHighBlock {
  margin: -14px 0;
  @include respond-above("bp-1010") {
    margin: -33.5px 0;
  }
}
.opportunityImg {
  width: 100%;

  @include respond-above("bp-768") {
    width: 160%;
  }
  @include respond-above("bp-1010") {
    width: 193%;
  }
  @include respond-above("bp-1142") {
    width: 160%;
  }
}
.animatedImgBlock {
  position: relative;
}
.animatedImg {
  width: 100%;
}
.opportunityVideo {
  width: 100%;
  @include respond-above("bp-768") {
    width: 160%;
  }
}
.message {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
}
