@import '~@/styles/abstracts.scss';

.section {
  height: 100%;

  @include respond-above('bp-1010') {
    height: 550px;
  }
}
.videoWrapper {
  height: 100%;
  margin: 0px auto;
}

.videoBlock {
  position: relative;
  transform: translateY(-88px);
  width: 100%;
  height: 100%;

  @include respond-above('bp-768') {
    transform: translateY(-130px);
  }
  @include respond-above('bp-1010') {
    transform: translateY(-340px);
  }
}
.videoBox {
  position: relative;
  width: 100%;
  background: var(--color-background-main) !important;
  box-shadow: var(--box-shadow);
  overflow: hidden;
}
.videoBox:before {
  content: '';
  display: block;
  padding-top: 56.25%;
}

.videoContent {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: calc(100% + 1px);
  height: calc(100% + 1px);
  background-color: var(--color-background-main) !important;
}
.videoContent img {
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: calc(100% + 1px);
  height: calc(100% + 1px);
}
.video {
  background-color: transparent !important;
}
