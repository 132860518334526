@import "~@/styles/abstracts.scss";

.section {
  height: 100%;
  background-color: var(--color-background-main);
}

.advantagesSection {
  padding-bottom: 73px;
  padding-top: 17px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @include respond-above("bp-768") {
    padding-bottom: 23px;
  }
  @include respond-above("bp-1010") {
    justify-content: start;
    padding-top: 50px;
    padding-bottom: 83px;
  }
}
.advantagesBlock {
  flex: 1 0 100%;
  padding-top: 45px;
  max-width: 325px;
  @include respond-above("bp-1010") {
    padding-top: 75px;
    max-width: 390px;
  }
  @include respond-above("bp-1142") {
    max-width: 426px;
  }
}
.advantagesBlock:nth-child(2n + 1) {
  @include respond-above("bp-1010") {
    margin-right: 35px;
  }
  @include respond-above("bp-1142") {
    margin-right: 54px;
  }
}
.advantagesText {
  padding-top: 12px;
}
.emptyEl {
  height: 157px;
}

.advantagesBlock_expand {
  margin-top: 44px;
}

.advantagesBlock_expand,
.advantagesBlock_expand > div {
  width: 100%;
  max-width: 426px;
}

.advantagesBlock_expand > div {
  margin: 0;
  margin-top: 24px;
}
