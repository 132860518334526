@import "~@/styles/abstracts.scss";

.section {
  --indent-from-video: -33px;

  height: 100%;
  margin-top: var(--indent-from-video);

  @include respond-above("bp-768") {
    text-align: center;
  }
  @include respond-above("bp-1010") {
    --indent-from-video: -262px;
  }
  @include respond-above("bp-1142") {
    height: 474px;
  }
}
.sectionContainer {
  position: relative;
  padding-bottom: calc(10px - var(--indent-from-video));

  @include respond-above("bp-768") {
    padding-bottom: 120px;
  }
  @include respond-above("bp-1010") {
    padding-bottom: 200px;
  }
}
.explanationSectionMotionDiv {
  transform: translateZ(0);
  transform-style: preserve-3d;
  backface-visibility: hidden;
  perspective: 1000;
}
.explanationHeading {
  @include respond-above("bp-768") {
    margin: 0 auto;
  }
}
.explanationText {
  padding: 11px 0 0px 0;
  letter-spacing: -0.02em;
  max-width: 400px;

  &:not(&_Small) > p {
    white-space: pre-wrap;
  }

  @include respond-above("bp-455") {
    max-width: 500px;
  }
  @include respond-above("bp-525") {
    margin-bottom: 100px;
  }
  @include respond-above("bp-625") {
    margin-bottom: 65px;
    margin-top: 15px;
  }
  @include respond-above("bp-768") {
    margin: 0 auto;
    max-width: 100%;
    margin-top: 0px;
    font-size: 24px;
  }
  @include respond-above("bp-1142") {
    margin-top: 0px;
    padding: 6px 0 0px 0;
    font-size: 34px;
  }
}
