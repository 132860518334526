@import "~@/styles/abstracts.scss";

.section {
  background: var(--color-background-auth);
  height: 350px;
  margin-top: 0px;

  transition: height 0.2s;
}
.basementSection {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.basementHeading {
  width: 290px;
  color: #fff;

  @include respond-above("bp-455") {
    width: 380px;
  }
  @include respond-above("bp-525") {
    width: 470px;
  }
  @include respond-above("bp-625") {
    width: 580px;
  }
}
.basementDownloadLinks {
  margin-top: 15px;
}
.footer {
  padding-top: 52px;
  padding-bottom: 21px;
  font-size: 13px;
  line-height: 1.54em;
  display: flex;
  justify-content: space-between;
  color: #fff;
}
