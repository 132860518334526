@import '~@/styles/abstracts.scss';

.authButton {
  --link-button-background-color: var(--color-background-main);
  --link-button-color: var(--color-primary);

  outline: none;

  background: var(--link-button-background-color);
  color: var(--link-button-color);
  font-family: 'Montserrat', sans-serif;
  font-size: 20px;
  height: 50px;
  line-height: 26px;
  font-weight: 700;
  padding: 11px 44px;
  border-radius: 6px;
  border: solid 1px rgb(218, 218, 218);

  transition: color 0.2s, background 0.3s;

  &:hover {
    --link-button-background-color: var(--color-primary);
    --link-button-color: var(--color-background-main);
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.8;
  }
  &.invert {
    background: var(--link-button-color);
    color: var(--link-button-background-color);
  }
}

.authed_user_button {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 10px;
  margin: -10px;
  margin-left: 10px;
  width: 100%;
}

.authed_user_button_icon {
  height: auto;
  width: 15px;
  min-width: 15px;
  margin-right: 8px;
}

.authed_user_button_text {
  font-size: 21px;
  font-family: 'MADEEvolveSans', sans-serif;
  font-weight: 400;
  letter-spacing: -0.048em;
  line-height: 28px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  margin-right: 14px;

  &:hover {
    text-decoration: underline;
  }
}
