@import '~@/styles/abstracts.scss';

.bodyBg {
  background-color: var(--color-background-soft);
  background-repeat: no-repeat;
  background-image: url(/img/invitation-redirect/phone@2x.png?2);
  background-size: 324px 300px;
  background-position: bottom left;
  background-position-x: 20px;
  background-position-y: 100%;
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .bodyBg {
    background-image: url(/img/invitation-redirect/phone@2x.png?2);
  }
}

.container {
  position: relative;
  height: 100vh;
}

.mainWrapper {
  position: relative;
  z-index: 1;
  padding: 0 var(--side-size);
  background-color: var(--color-background-soft);
  box-shadow: 0px 20px 15px rgb(244, 237, 228);
}

.downloadHeading {
  margin-top: 18px;
}

.downloadText {
  margin-top: 16px;
}

.mainDownloadLinks {
  margin-top: 12px;
}

.openHeading {
  margin-top: 31px;
}

.openText {
  margin-top: 15px;
}

.startButton {
  outline: none;
  background: #000;
  color: white;
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  height: 42px;
  line-height: 42px;
  font-weight: 700;
  padding: 0px 36px;
  border-radius: 3px;
  transition: color 0.2s, background 0.3s;
  margin-top: 13px;
}

.footerText {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 50px;

  display: flex;
  align-items: center;
  justify-content: center;

  background: var(--color-background-soft);
  box-shadow: 0px -20px 15px rgb(244, 237, 228);
}
