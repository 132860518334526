html {
  box-sizing: border-box;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  min-width: var(--min-page-width);
}

a {
  display: inline-block;
  color: inherit;
  text-decoration: inherit;
}

button {
  font: inherit;
  color: inherit;
  background: inherit;
  border: none;
}

a,
button {
  pointer-events: auto;
  cursor: pointer;
}
button,
button:active,
button:focus {
  box-shadow: none !important;
  outline: none !important;
}

svg {
  width: 100%;
  height: 100%;
}

input {
  box-shadow: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}
// :focus {
//   outline: 0 !important;
// }
